// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCrobFvE9-3Ae0IuMlsFpfsMGX5no_Yg0k",
  authDomain: "dpm-route-recommendation.firebaseapp.com",
  projectId: "dpm-route-recommendation",
  storageBucket: "dpm-route-recommendation.appspot.com",
  messagingSenderId: "183162725937",
  appId: "1:183162725937:web:67beb7fa790de53e571710",
  measurementId: "G-ZQJNF0761V",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { app, db };
