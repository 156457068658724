// Tables are found in this file.
// Navigate below to see examples.
// Copy one to create new ones

class RouteEntry {
  public name: string;
  public walkTime: number;
  public sidewalkQuality: number;
  public crimeSafety: number;
  public trafficSafety: number;
  public thermalComfort: number;

  constructor(
    name: string,
    walkTime: number,
    sidewalkQuality: number,
    crimeSafety: number,
    trafficSafety: number,
    thermalComfort: number
  ) {
    this.name = name;
    this.walkTime = walkTime;
    this.sidewalkQuality = sidewalkQuality;
    this.crimeSafety = crimeSafety;
    this.trafficSafety = trafficSafety;
    this.thermalComfort = thermalComfort;
  }

  getWalkTimeString(): string {
    return String(this.walkTime);
  }

  getSidewalkQualityString(isSpanish: boolean): string {
    const en = ["Invalid", "Bad", "Moderate", "Good"];
    const es = ["Inválido", "Mala", "Moderada", "Buena"];
    if (!isSpanish) return en[this.sidewalkQuality];
    else return es[this.sidewalkQuality];
  }

  getCrimeSafetyString(isSpanish: boolean): string {
    const en = ["Invalid", "Low safe", "Moderate", "High safe"];
    const es = ["Inválido", "Baja seguridad", "Moderada", "Alta Seguridad"];
    if (!isSpanish) return en[this.crimeSafety];
    else return es[this.crimeSafety];
  }

  getTrafficSafetyString(isSpanish: boolean): string {
    const en = ["Invalid", "Low safe", "Moderate", "High safe"];
    const es = ["Inválido", "Baja seguridad", "Moderada", "Alta Seguridad"];
    if (!isSpanish) return en[this.trafficSafety];
    else return es[this.trafficSafety];
  }

  getThermalComfortString(isSpanish: boolean): string {
    const en = ["Invalid", "Hot", "Warm", "Neutral"];
    const es = ["Inválido", "Caliente", "Cálida", "Neutral"];
    if (!isSpanish) return en[this.thermalComfort];
    else return es[this.thermalComfort];
  }
}

const tables_v1: RouteEntry[][] = [
  [
    new RouteEntry("Path 1", 12, 2, 2, 2, 3),
    new RouteEntry("Path 2", 18, 1, 2, 3, 3),
  ],
  [
    new RouteEntry("Path 3", 14, 2, 3, 2, 2),
    new RouteEntry("Path 4", 7, 1, 2, 1, 2),
  ],
  [
    new RouteEntry("Path 5", 10, 1, 3, 1, 3),
    new RouteEntry("Path 6", 20, 3, 2, 2, 2),
  ],
  [
    new RouteEntry("Path 7", 13, 1, 3, 3, 1),
    new RouteEntry("Path 8", 19, 1, 3, 3, 3),
  ],
  [
    new RouteEntry("Path 9", 18, 3, 2, 3, 3),
    new RouteEntry("Path 10", 10, 3, 3, 3, 1),
  ],
  [
    new RouteEntry("Path 11", 5, 1, 3, 2, 2),
    new RouteEntry("Path 12", 9, 2, 3, 3, 2),
  ],
  [
    new RouteEntry("Path 13", 20, 1, 1, 2, 1),
    new RouteEntry("Path 14", 19, 3, 1, 2, 1),
  ],
  [
    new RouteEntry("Path 15", 11, 2, 1, 2, 1),
    new RouteEntry("Path 16", 13, 3, 1, 1, 3),
  ],
  [
    new RouteEntry("Path 17", 12, 2, 3, 2, 1),
    new RouteEntry("Path 18", 16, 3, 2, 1, 1),
  ],
  [
    new RouteEntry("Path 19", 11, 3, 2, 2, 3),
    new RouteEntry("Path 20", 17, 1, 2, 1, 1),
  ],
  [
    new RouteEntry("Path 21", 5, 1, 1, 3, 1),
    new RouteEntry("Path 22", 6, 1, 2, 3, 2),
  ],
  [
    new RouteEntry("Path 23", 14, 3, 2, 1, 2),
    new RouteEntry("Path 24", 6, 2, 3, 3, 3),
  ],
  [
    new RouteEntry("Path 25", 15, 3, 3, 2, 2),
    new RouteEntry("Path 26", 6, 3, 1, 1, 3),
  ],
  [
    new RouteEntry("Path 27", 15, 3, 1, 3, 1),
    new RouteEntry("Path 28", 13, 1, 1, 1, 2),
  ],
  [
    new RouteEntry("Path 29", 16, 2, 3, 1, 2),
    new RouteEntry("Path 30", 15, 2, 1, 1, 3),
  ],
  [
    new RouteEntry("Path 31", 8, 2, 2, 3, 3),
    new RouteEntry("Path 32", 17, 2, 3, 2, 2),
  ],
  [
    new RouteEntry("Path 33", 9, 2, 2, 3, 1),
    new RouteEntry("Path 34", 7, 1, 1, 1, 1),
  ],
  [
    new RouteEntry("Path 35", 14, 3, 1, 2, 3),
    new RouteEntry("Path 36", 8, 2, 1, 1, 2),
  ],
];

const tables_v2: RouteEntry[][] = [
  [
    new RouteEntry("Path 1", 8, 3, 2, 2, 1),
    new RouteEntry("Path 2", 7, 3, 3, 2, 3),
  ],
  [
    new RouteEntry("Path 3", 17, 2, 2, 1, 2),
    new RouteEntry("Path 4", 13, 2, 2, 3, 3),
  ],
  [
    new RouteEntry("Path 5", 12, 1, 1, 1, 3),
    new RouteEntry("Path 6", 16, 2, 1, 2, 1),
  ],
  [
    new RouteEntry("Path 7", 9, 3, 1, 1, 2),
    new RouteEntry("Path 8", 10, 1, 1, 2, 2),
  ],
  [
    new RouteEntry("Path 9", 20, 3, 2, 1, 2),
    new RouteEntry("Path 10", 19, 1, 3, 2, 2),
  ],
  [
    new RouteEntry("Path 11", 15, 1, 3, 2, 1),
    new RouteEntry("Path 12", 15, 3, 3, 1, 3),
  ],
  [
    new RouteEntry("Path 13", 8, 1, 3, 2, 1),
    new RouteEntry("Path 14", 14, 3, 1, 3, 1),
  ],
  [
    new RouteEntry("Path 15", 12, 3, 1, 3, 1),
    new RouteEntry("Path 16", 10, 1, 2, 1, 2),
  ],
  [
    new RouteEntry("Path 17", 11, 1, 1, 3, 3),
    new RouteEntry("Path 18", 5, 3, 2, 1, 3),
  ],
  [
    new RouteEntry("Path 19", 5, 2, 2, 2, 2),
    new RouteEntry("Path 20", 17, 2, 1, 3, 1),
  ],
  [
    new RouteEntry("Path 21", 17, 3, 3, 1, 3),
    new RouteEntry("Path 22", 18, 1, 1, 3, 2),
  ],
  [
    new RouteEntry("Path 23", 8, 2, 3, 2, 1),
    new RouteEntry("Path 24", 9, 1, 2, 3, 1),
  ],
  [
    new RouteEntry("Path 25", 18, 2, 1, 3, 2),
    new RouteEntry("Path 26", 13, 1, 3, 2, 3),
  ],
  [
    new RouteEntry("Path 27", 12, 3, 3, 1, 3),
    new RouteEntry("Path 28", 19, 2, 3, 1, 2),
  ],
  [
    new RouteEntry("Path 29", 14, 2, 3, 3, 3),
    new RouteEntry("Path 30", 6, 3, 2, 2, 1),
  ],
  [
    new RouteEntry("Path 31", 16, 1, 1, 3, 2),
    new RouteEntry("Path 32", 10, 2, 1, 1, 3),
  ],
  [
    new RouteEntry("Path 33", 7, 3, 3, 3, 1),
    new RouteEntry("Path 34", 20, 1, 2, 2, 3),
  ],
  [
    new RouteEntry("Path 35", 6, 2, 2, 3, 2),
    new RouteEntry("Path 36", 11, 2, 2, 1, 1),
  ],
];

const tables_v3: RouteEntry[][] = [
  [
    new RouteEntry("Path 1", 16, 1, 3, 3, 3),
    new RouteEntry("Path 2", 19, 3, 2, 2, 2),
  ],
  [
    new RouteEntry("Path 3", 7, 2, 3, 2, 2),
    new RouteEntry("Path 4", 10, 3, 3, 3, 2),
  ],
  [
    new RouteEntry("Path 5", 11, 1, 3, 1, 2),
    new RouteEntry("Path 6", 15, 2, 2, 1, 1),
  ],
  [
    new RouteEntry("Path 7", 9, 1, 1, 1, 2),
    new RouteEntry("Path 8", 15, 1, 2, 3, 2),
  ],
  [
    new RouteEntry("Path 9", 8, 3, 3, 3, 2),
    new RouteEntry("Path 10", 5, 2, 3, 3, 3),
  ],
  [
    new RouteEntry("Path 11", 12, 3, 1, 3, 3),
    new RouteEntry("Path 12", 16, 3, 3, 3, 1),
  ],
  [
    new RouteEntry("Path 13", 17, 3, 1, 2, 1),
    new RouteEntry("Path 14", 9, 3, 2, 2, 1),
  ],
  [
    new RouteEntry("Path 15", 20, 2, 2, 1, 3),
    new RouteEntry("Path 16", 12, 2, 2, 2, 2),
  ],
  [
    new RouteEntry("Path 17", 18, 2, 3, 1, 1),
    new RouteEntry("Path 18", 18, 2, 1, 1, 3),
  ],
  [
    new RouteEntry("Path 19", 9, 1, 3, 1, 3),
    new RouteEntry("Path 20", 20, 2, 1, 3, 1),
  ],
  [
    new RouteEntry("Path 21", 6, 2, 1, 1, 3),
    new RouteEntry("Path 22", 7, 1, 3, 3, 1),
  ],
  [
    new RouteEntry("Path 23", 17, 3, 1, 2, 3),
    new RouteEntry("Path 24", 13, 2, 2, 3, 2),
  ],
  [
    new RouteEntry("Path 25", 19, 3, 2, 1, 3),
    new RouteEntry("Path 26", 10, 1, 1, 2, 1),
  ],
  [
    new RouteEntry("Path 27", 6, 1, 1, 2, 3),
    new RouteEntry("Path 28", 14, 1, 2, 2, 3),
  ],
  [
    new RouteEntry("Path 29", 13, 2, 2, 2, 1),
    new RouteEntry("Path 30", 14, 1, 2, 3, 1),
  ],
  [
    new RouteEntry("Path 31", 11, 1, 1, 3, 2),
    new RouteEntry("Path 32", 17, 3, 3, 1, 1),
  ],
  [
    new RouteEntry("Path 33", 5, 3, 1, 1, 2),
    new RouteEntry("Path 34", 8, 1, 1, 1, 3),
  ],
  [
    new RouteEntry("Path 35", 20, 2, 3, 2, 1),
    new RouteEntry("Path 36", 11, 3, 2, 2, 2),
  ],
];

const tables_v4: RouteEntry[][] = [
  [
    new RouteEntry("Path 1", 12, 3, 2, 3, 2),
    new RouteEntry("Path 2", 19, 2, 1, 3, 2),
  ],
  [
    new RouteEntry("Path 3", 14, 1, 1, 3, 1),
    new RouteEntry("Path 4", 18, 1, 3, 2, 1),
  ],
  [
    new RouteEntry("Path 5", 16, 2, 2, 1, 3),
    new RouteEntry("Path 6", 9, 3, 1, 2, 3),
  ],
  [
    new RouteEntry("Path 7", 6, 2, 3, 1, 1),
    new RouteEntry("Path 8", 20, 2, 3, 1, 1),
  ],
  [
    new RouteEntry("Path 9", 5, 2, 1, 1, 1),
    new RouteEntry("Path 10", 6, 1, 2, 1, 1),
  ],
  [
    new RouteEntry("Path 11", 18, 3, 3, 2, 2),
    new RouteEntry("Path 12", 19, 3, 3, 1, 1),
  ],
  [
    new RouteEntry("Path 13", 12, 1, 2, 2, 2),
    new RouteEntry("Path 14", 17, 1, 1, 3, 2),
  ],
  [
    new RouteEntry("Path 15", 5, 2, 3, 2, 2),
    new RouteEntry("Path 16", 19, 1, 2, 1, 1),
  ],
  [
    new RouteEntry("Path 17", 7, 2, 1, 1, 3),
    new RouteEntry("Path 18", 11, 1, 1, 2, 3),
  ],
  [
    new RouteEntry("Path 19", 13, 2, 3, 3, 3),
    new RouteEntry("Path 20", 9, 2, 1, 2, 1),
  ],
  [
    new RouteEntry("Path 21", 18, 3, 2, 2, 3),
    new RouteEntry("Path 22", 10, 2, 2, 2, 3),
  ],
  [
    new RouteEntry("Path 23", 16, 3, 3, 2, 1),
    new RouteEntry("Path 24", 10, 2, 1, 3, 2),
  ],
  [
    new RouteEntry("Path 25", 7, 3, 2, 1, 3),
    new RouteEntry("Path 26", 15, 1, 3, 3, 3),
  ],
  [
    new RouteEntry("Path 27", 5, 3, 2, 3, 1),
    new RouteEntry("Path 28", 17, 1, 2, 3, 3),
  ],
  [
    new RouteEntry("Path 29", 20, 3, 1, 3, 2),
    new RouteEntry("Path 30", 13, 3, 1, 1, 2),
  ],
  [
    new RouteEntry("Path 31", 9, 1, 3, 1, 2),
    new RouteEntry("Path 32", 8, 3, 3, 3, 3),
  ],
  [
    new RouteEntry("Path 33", 11, 2, 3, 1, 1),
    new RouteEntry("Path 34", 8, 1, 2, 2, 3),
  ],
  [
    new RouteEntry("Path 35", 15, 3, 1, 3, 2),
    new RouteEntry("Path 36", 14, 1, 2, 2, 2),
  ],
];

const tables_v5: RouteEntry[][] = [
  [
    new RouteEntry("Path 1", 11, 3, 3, 1, 2),
    new RouteEntry("Path 2", 16, 3, 2, 1, 2),
  ],
  [
    new RouteEntry("Path 3", 7, 1, 2, 3, 1),
    new RouteEntry("Path 4", 7, 3, 2, 3, 3),
  ],
  [
    new RouteEntry("Path 5", 19, 1, 2, 2, 3),
    new RouteEntry("Path 6", 15, 2, 2, 2, 2),
  ],
  [
    new RouteEntry("Path 7", 13, 1, 3, 1, 1),
    new RouteEntry("Path 8", 17, 1, 3, 2, 3),
  ],
  [
    new RouteEntry("Path 9", 9, 3, 3, 2, 3),
    new RouteEntry("Path 10", 10, 3, 3, 3, 3),
  ],
  [
    new RouteEntry("Path 11", 19, 2, 1, 3, 3),
    new RouteEntry("Path 12", 7, 2, 1, 2, 2),
  ],
  [
    new RouteEntry("Path 13", 12, 1, 3, 1, 2),
    new RouteEntry("Path 14", 6, 3, 1, 2, 1),
  ],
  [
    new RouteEntry("Path 15", 11, 3, 2, 3, 1),
    new RouteEntry("Path 16", 16, 1, 1, 2, 2),
  ],
  [
    new RouteEntry("Path 17", 5, 3, 1, 3, 1),
    new RouteEntry("Path 18", 16, 2, 1, 2, 3),
  ],
  [
    new RouteEntry("Path 19", 20, 1, 1, 1, 3),
    new RouteEntry("Path 20", 18, 2, 1, 2, 1),
  ],
  [
    new RouteEntry("Path 21", 17, 2, 2, 3, 2),
    new RouteEntry("Path 22", 8, 2, 2, 3, 1),
  ],
  [
    new RouteEntry("Path 23", 11, 2, 3, 3, 3),
    new RouteEntry("Path 24", 6, 3, 3, 3, 2),
  ],
  [
    new RouteEntry("Path 25", 13, 3, 1, 2, 2),
    new RouteEntry("Path 26", 15, 1, 2, 1, 1),
  ],
  [
    new RouteEntry("Path 27", 14, 3, 3, 1, 1),
    new RouteEntry("Path 28", 20, 1, 3, 3, 2),
  ],
  [
    new RouteEntry("Path 29", 9, 2, 2, 3, 3),
    new RouteEntry("Path 30", 8, 3, 1, 1, 2),
  ],
  [
    new RouteEntry("Path 31", 15, 2, 1, 2, 3),
    new RouteEntry("Path 32", 5, 1, 3, 2, 3),
  ],
  [
    new RouteEntry("Path 33", 12, 1, 1, 1, 1),
    new RouteEntry("Path 34", 10, 2, 2, 1, 1),
  ],
  [
    new RouteEntry("Path 35", 14, 2, 3, 1, 2),
    new RouteEntry("Path 36", 18, 1, 2, 1, 1),
  ],
];

export { RouteEntry, tables_v1, tables_v2, tables_v3, tables_v4, tables_v5 };
