import "./Survey.css";
import { BackButton, NextButton, SpanishButton } from "../Components/Button";
import help_button from "../Assets/help_button.png";
import RadioButton from "../Components/RadioButton";
import image1 from "../Assets/landing_1.png";
import image2 from "../Assets/landing_2.png";
import { useGlobalContext } from "../GlobalContext";

function SurveyPurposefulWalks(props: { next: any }) {
  const { isSpanish } = useGlobalContext();

  return (
    <div className="landing-container">
      <div className="top-right-button" style={{ zIndex: 1000 }}>
        <SpanishButton />
      </div>
      <div className="survey-container">
        <div className="survey-image">
          <img src={image1} alt="Whole Foods Market storefront" />
        </div>

        {!isSpanish ? (
          <div className="survey-content">
            <h1>Survey</h1>
            <p style={{ fontSize: "24pt" }}>
              Based on five attributes, select your preferred path when you walk
              as <strong>transportation method.</strong>
            </p>
            <p style={{ fontSize: "24pt" }}>
              Click on the help icon{" "}
              <img
                src={help_button}
                alt="Help icon"
                style={{
                  display: "inline-block",
                  width: "18px",
                  transform: "scale(1.5)",
                  verticalAlign: "text-bottom",
                  margin: "0 4px 8px 4px",
                }}
              />{" "}
              to review the details of each attributes.
            </p>
          </div>
        ) : (
          <div className="survey-content">
            <h1>La Encuesta</h1>
            <p style={{ fontSize: "24pt" }}>
              Basado en los cinco atributos, seleccione su ruta preferida cuando
              camina para los paseos con propósito, camina como{" "}
              <strong>metodo de transportacion</strong> (Ej. para llegar a algún
              lugar).
            </p>
            <p style={{ fontSize: "24pt" }}>
              Presione el ícono de ayuda
              <img
                src={help_button}
                alt="Help icon"
                style={{
                  display: "inline-block",
                  width: "18px",
                  transform: "scale(1.5)",
                  verticalAlign: "text-bottom",
                  margin: "0 4px 8px 4px",
                  paddingLeft: "5px",
                }}
              />{" "}
              para revisar los detalles de cada atributo.
            </p>
          </div>
        )}
      </div>
      <div className="bottom-right-button">
        <NextButton buttonAction={props.next} />
      </div>
    </div>
  );
}

function SurveyRecreationalWalks(props: { next: any }) {
  const { isSpanish } = useGlobalContext();
  return (
    <div className="landing-container">
      <div className="top-right-button" style={{ zIndex: 1000 }}>
        <SpanishButton />
      </div>
      <div className="survey-container">
        <div className="survey-image">
          <img src={image2} alt="Adults walking" style={{ marginTop: "10%" }} />
        </div>

        <div className="survey-content">
          {!isSpanish ? (
            <>
              <h1>Recreational Walks Survey</h1>
              <p style={{ fontSize: "24pt" }}>
                Next, based on the five attributes, select your preferred path
                when you walk for{" "}
                <strong>recreation, sport, exercise, or leisure</strong>
              </p>
            </>
          ) : (
            <>
              <h1>Los Paseos Recreativos y Divertidos</h1>
              <p style={{ fontSize: "24pt" }}>
                A continuación, basado en los cinco atributos, seleccione su
                ruta preferida cuando camina por{" "}
                <strong>DIVERSION, DEPORTE, EJERCICIO o ESPARCIMIENTO</strong>.
              </p>
            </>
          )}

          {!isSpanish ? (
            <>
              {" "}
              <p style={{ fontSize: "24pt" }}>
                Click on the help icon{" "}
                <img
                  src={help_button}
                  alt="Help icon"
                  style={{
                    display: "inline-block",
                    width: "18px",
                    transform: "scale(1.5)",
                    verticalAlign: "text-bottom",
                    margin: "0 4px 8px 4px",
                  }}
                />{" "}
                to review the details of each attributes.
              </p>
            </>
          ) : (
            <>
              {" "}
              <p style={{ fontSize: "24pt" }}>
                Presione el ícono de ayuda{" "}
                <img
                  src={help_button}
                  alt="Help icon"
                  style={{
                    display: "inline-block",
                    width: "18px",
                    transform: "scale(1.5)",
                    verticalAlign: "text-bottom",
                    margin: "0 4px 8px 4px",
                    paddingLeft: "5px",
                  }}
                />{" "}
                para revisar los detalles de cada atributo.
              </p>
            </>
          )}
        </div>
      </div>
      <div className="bottom-right-button">
        <NextButton buttonAction={props.next} />
      </div>
    </div>
  );
}

function SurveySelection(props: {
  back: any;
  next: any;
  surveyVersion: any;
  setSurveyVersion: any;
}) {
  const { isSpanish } = useGlobalContext();

  return (
    <div className="landing-container">
      <div style={{ textAlign: "left", marginLeft: "0%", width: "90%" }}>
        <div style={{ marginBottom: "8%" }}>
          {!isSpanish ? (
            <>
              {" "}
              <h1>Survey Version</h1>
              <p>
                Please select the survey version you were instructed to take.
              </p>
            </>
          ) : (
            <>
              {" "}
              <h1>Versión de la Encuesta</h1>
              <p>
                Por favor, seleccione la versión de la encuesta que se le indicó
                que completara.
              </p>
            </>
          )}
        </div>
      </div>

      {/* Version 1 */}
      <div className="radio-button-with-label">
        <RadioButton
          selected={props.surveyVersion === 1}
          onClick={() => props.setSurveyVersion(1)}
        />
        <span className="radio-button-label">
          {!isSpanish ? "Version " : "Versión "}1
        </span>
      </div>
      {/* Version 2 */}
      <div className="radio-button-with-label">
        <RadioButton
          selected={props.surveyVersion === 2}
          onClick={() => props.setSurveyVersion(2)}
        />
        <span className="radio-button-label">
          {" "}
          {!isSpanish ? "Version " : "Versión "}2
        </span>
      </div>
      {/* Version 3 */}
      <div className="radio-button-with-label">
        <RadioButton
          selected={props.surveyVersion === 3}
          onClick={() => props.setSurveyVersion(3)}
        />
        <span className="radio-button-label">
          {!isSpanish ? "Version " : "Versión "} 3
        </span>
      </div>
      {/* Version 4 */}
      <div className="radio-button-with-label">
        <RadioButton
          selected={props.surveyVersion === 4}
          onClick={() => props.setSurveyVersion(4)}
        />
        <span className="radio-button-label">
          {!isSpanish ? "Version " : "Versión "} 4
        </span>
      </div>
      <div className="radio-button-with-label">
        <RadioButton
          selected={props.surveyVersion === 5}
          onClick={() => props.setSurveyVersion(5)}
        />
        <span className="radio-button-label">
          {!isSpanish ? "Version " : "Versión "} 5
        </span>
      </div>
      <br />

      <div className="bottom-left-button">
        <BackButton buttonAction={props.back} />
      </div>
      <div className="bottom-right-button">
        <NextButton
          buttonAction={props.next}
          disable={props.surveyVersion === 0}
        />
      </div>
    </div>
  );
}

export { SurveyPurposefulWalks, SurveyRecreationalWalks, SurveySelection };
