import { useState } from "react";

import { NextButton, SpanishButton } from "../Components/Button";
import { ThermalComfortTable } from "../Education/ThermalComfort";
import "./Quiz.css";
import { useGlobalContext } from "../GlobalContext";

function Quiz4(props: { next: any; incrementScore: any }) {
  const [selected, setSelected] = useState<number>(0);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const { isSpanish } = useGlobalContext();

  const correct: number = 2;

  const handleSubmit = () => {
    setSubmitted(true);
    if (selected === correct) {
      props.incrementScore();
    }
  };

  const handleNext = () => {
    props.next();
  };

  return (
    <div className="landing-container">
      <div className="top-right-button">
        <SpanishButton />
      </div>

      {!submitted &&
        (!isSpanish ? (
          <>
            <div style={{ textAlign: "left", marginLeft: "0%", width: "90%" }}>
              <h2>Question 4</h2>
              <p>
                Individuals feel more comfortable and pleasant with the walking
                environment in _______.
              </p>
              <div className="quiz-options">
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="option"
                    onChange={() => setSelected(1)}
                  />
                  <span className="custom-radio-checkmark"></span>
                  <strong>hot</strong> thermal comfort
                </label>
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="option"
                    onChange={() => setSelected(2)}
                  />
                  <span className="custom-radio-checkmark"></span>
                  <strong>warm</strong> thermal comfort
                </label>
              </div>

              <div className="bottom-right-button">
                <NextButton
                  buttonAction={handleSubmit}
                  disable={selected === 0}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div style={{ textAlign: "left", marginLeft: "0%", width: "90%" }}>
              <h2>Pregunta 4</h2>
              <p>
                Las personas se sienten más cómodas y agradables con el entorno
                de caminata en _______.
              </p>
              <div className="quiz-options">
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="option"
                    onChange={() => setSelected(1)}
                  />
                  <span className="custom-radio-checkmark"></span>
                  Comodidad térmica <strong>caliente</strong>
                </label>
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="option"
                    onChange={() => setSelected(2)}
                  />
                  <span className="custom-radio-checkmark"></span>
                  Comodidad térmica <strong>cálida</strong>
                </label>
              </div>

              <div className="bottom-right-button">
                <NextButton
                  buttonAction={handleSubmit}
                  disable={selected === 0}
                />
              </div>
            </div>
          </>
        ))}

      {submitted &&
        selected !== correct &&
        (!isSpanish ? (
          <>
            <div style={{ textAlign: "left", marginLeft: "0%", width: "90%" }}>
              <h2>💡&nbsp;&nbsp;Nice try!</h2>
              <p>
                Individuals feel more comfortable and pleasant with the walking
                environment in <strong>warm</strong> thermal comfort.
              </p>
              <br />
              <ThermalComfortTable />

              <div className="bottom-right-button">
                <NextButton buttonAction={handleNext} disable={false} />
              </div>
            </div>
          </>
        ) : (
          <>
            <div style={{ textAlign: "left", marginLeft: "0%", width: "90%" }}>
              <h2>💡&nbsp;&nbsp;¡Buen intento!</h2>
              <p>
                Las personas se sienten más cómodas y agradables con el entorno
                de caminata en <strong>comodidad térmica cálida</strong>.
              </p>
              <br />
              <ThermalComfortTable />

              <div className="bottom-right-button">
                <NextButton buttonAction={handleNext} disable={false} />
              </div>
            </div>
          </>
        ))}

      {submitted &&
        selected === correct &&
        (!isSpanish ? (
          <>
            <div style={{ textAlign: "left", marginLeft: "0%", width: "90%" }}>
              <h2>✅&nbsp;&nbsp;Correct!</h2>
              <p>
                Individuals feel more comfortable and pleasant with the walking
                environment in <strong>warm</strong> thermal comfort.
              </p>
              <div className="bottom-right-button">
                <NextButton buttonAction={handleNext} disable={false} />
              </div>
            </div>
          </>
        ) : (
          <>
            <div style={{ textAlign: "left", marginLeft: "0%", width: "90%" }}>
              <h2>✅&nbsp;&nbsp;¡Correcto!</h2>
              <p>
                Las personas se sienten más cómodas y agradables con el entorno
                de caminata en <strong>comodidad térmica cálida</strong>.
              </p>
              <div className="bottom-right-button">
                <NextButton buttonAction={handleNext} disable={false} />
              </div>
            </div>
          </>
        ))}
    </div>
  );
}

export default Quiz4;
