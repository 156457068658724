import { useState } from "react";

import { NextButton, SpanishButton } from "../Components/Button";
import { TrafficSafetyTable } from "../Education/TrafficSafety";
import "./Quiz.css";
import { useGlobalContext } from "../GlobalContext";

function Quiz3(props: { next: any; incrementScore: any }) {
  const [selected, setSelected] = useState<number>(0);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const { isSpanish } = useGlobalContext();

  const correct: number = 1;

  const handleSubmit = () => {
    setSubmitted(true);
    if (selected === correct) {
      props.incrementScore();
    }
  };

  const handleNext = () => {
    props.next();
  };

  return (
    <div className="landing-container">
      <div className="top-right-button">
        <SpanishButton />
      </div>

      {!submitted &&
        (!isSpanish ? (
          <>
            <div style={{ textAlign: "left", marginLeft: "0%", width: "90%" }}>
              <h2>Question 3</h2>
              <p>
                A path that has low traffic speed or wide sidewalk buffer is
                perceived as _______.
              </p>
              <div className="quiz-options">
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="option"
                    onChange={() => setSelected(1)}
                  />
                  <span className="custom-radio-checkmark"></span>
                  <strong>High</strong> traffic safety
                </label>
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="option"
                    onChange={() => setSelected(2)}
                  />
                  <span className="custom-radio-checkmark"></span>
                  <strong>Low</strong> traffic safety
                </label>
              </div>

              <div className="bottom-right-button">
                <NextButton
                  buttonAction={handleSubmit}
                  disable={selected === 0}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div style={{ textAlign: "left", marginLeft: "0%", width: "90%" }}>
              <h2>Pregunta 3</h2>
              <p>
                Una acero con baja velocidad de tráfico o con aceras amplias se
                percibe como _______?
              </p>
              <div className="quiz-options">
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="option"
                    onChange={() => setSelected(1)}
                  />
                  <span className="custom-radio-checkmark"></span>
                  Un camino de <strong>alta seguridad</strong>
                </label>
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="option"
                    onChange={() => setSelected(2)}
                  />
                  <span className="custom-radio-checkmark"></span>
                  Un camino de <strong>baja seguridad</strong>
                </label>
              </div>

              <div className="bottom-right-button">
                <NextButton
                  buttonAction={handleSubmit}
                  disable={selected === 0}
                />
              </div>
            </div>
          </>
        ))}

      {submitted &&
        selected !== correct &&
        (!isSpanish ? (
          <>
            <div style={{ textAlign: "left", marginLeft: "0%", width: "90%" }}>
              <h2>💡&nbsp;&nbsp;Nice try!</h2>
              <p>
                A path that has low traffic speed or wide sidewalk buffer is
                perceived as <strong>high</strong> traffic safety.
              </p>
              <br />
              <TrafficSafetyTable />

              <div className="bottom-right-button">
                <NextButton buttonAction={handleNext} disable={false} />
              </div>
            </div>
          </>
        ) : (
          <>
            <div style={{ textAlign: "left", marginLeft: "0%", width: "90%" }}>
              <h2>💡&nbsp;&nbsp;¡Buen intento!</h2>
              <p>
                Un camino con baja velocidad de tráfico o con aceras amplias se
                percibe como un camino de <strong>alta seguridad</strong>.
              </p>
              <br />
              <TrafficSafetyTable />

              <div className="bottom-right-button">
                <NextButton buttonAction={handleNext} disable={false} />
              </div>
            </div>
          </>
        ))}

      {submitted &&
        selected === correct &&
        (!isSpanish ? (
          <>
            <div style={{ textAlign: "left", marginLeft: "0%", width: "90%" }}>
              <h2>✅&nbsp;&nbsp;Correct!</h2>
              <p>
                A path that has low traffic speed or wide sidewalk buffer is
                perceived as <strong>high</strong> traffic safety.
              </p>

              <div className="bottom-right-button">
                <NextButton buttonAction={handleNext} disable={false} />
              </div>
            </div>
          </>
        ) : (
          <>
            <div style={{ textAlign: "left", marginLeft: "0%", width: "90%" }}>
              <h2>✅&nbsp;&nbsp;¡Correcto!</h2>
              <p>
                Una acero con baja velocidad de tráfico o con aceras anchas se
                percibe como <strong>alta seguridad</strong>.
              </p>

              <div className="bottom-right-button">
                <NextButton buttonAction={handleNext} disable={false} />
              </div>
            </div>
          </>
        ))}
    </div>
  );
}

export default Quiz3;
