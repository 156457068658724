import "./LandingPage.css";
import { useState, useEffect } from "react";

import { NextButton, SpanishButton } from "../Components/Button";

import warning_emoji from "../Assets/warning-emoji.png";
import logo from "../Assets/logo_pen.png";
import { useGlobalContext } from "../GlobalContext";

function LandingPage(props: { next: any; setSlideIndex: any }) {
  const { isSpanish } = useGlobalContext();
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  return isMobile ? (
    <div id="" className="mobile-content-container">
      <h3>
        <img
          src={warning_emoji}
          alt=""
          style={{ width: "20%", height: "auto", marginBottom: "10%" }}
        />
        Welcome to{" "}
        <div style={{ color: "#2D4191", marginBottom: "-5%" }}>
          Route Choice Survey
        </div>
      </h3>
      <p style={{ fontSize: "28px" }}>
        Unfortunately, this survey can only be taken on a{" "}
        <strong>Desktop device</strong>. Please visit this web page on a
        computer.
      </p>
    </div>
  ) : (
    <div id="" className="landing-container">
      <div className="top-right-button">
        <SpanishButton />
      </div>

      <div className="landing-row">
        <img
          src={logo}
          alt="Logo"
          style={{
            height: "auto",
            maxWidth: "20%",
            marginRight: "4%",
            marginBottom: "5%",
          }}
        />
        <div className="landing-content">
          {/* based on lang */}
          {!isSpanish ? (
            <>
              <h1>
                Welcome to{" "}
                <span style={{ color: "#2D4191" }}>Route Choice Survey!</span>
              </h1>

              <p>
                Press "Next" to start the survey. Please adjust the browser zoom
                level if necessary.
              </p>

              <p>
                This survey will take <strong>15-25</strong> minutes.
              </p>
              <div className="bottom-right-button">
                <NextButton buttonAction={props.next} />
              </div>
            </>
          ) : (
            <>
              <h1>
                ¡Bienvenido/a a la
                <span style={{ color: "#2D4191" }}>
                  {" "}
                  Encuesta de Elección de Ruta
                </span>
                !
              </h1>

              <p>
                Presione “Siguiente” para empezar la encuesta. Por favor, ajuste
                el navegador si es necesario.
              </p>

              <p>
                Esta encuesta durará aproximadamente <strong>15-25</strong>{" "}
                minutos.
              </p>
              <div className="bottom-right-button">
                <NextButton buttonAction={props.next} />
              </div>
            </>
          )}
        </div>
      </div>

      <div className="buttons-container">
        <button onClick={() => props.setSlideIndex(25)}>Skip to quiz</button>
        <button onClick={() => props.setSlideIndex(31)}>
          Skip to first survey
        </button>
        {/* Correct is 51 */}
        <button onClick={() => props.setSlideIndex(70)}>
          Skip to second survey
        </button>
      </div>
    </div>
  );
}

export default LandingPage;
