import {
  BackButton,
  CloseButton,
  NextButton,
  SpanishButton,
} from "../Components/Button";
import LevelTag from "../Components/LevelTag";
import sidewalk_bad from "../Assets/sidewalk_bad.jpg";
import sidewalk_moderate from "../Assets/sidewalk_moderate.jpg";
import sidewalk_good from "../Assets/sidewalk_good.jpg";
import sidewalk_condition from "../Assets/sidewalk-condition.png";
import { CSSProperties } from "react";

import "./General.css";
import ProgressBar from "../Components/ProgressBar";
import { useGlobalContext } from "../GlobalContext";

const buttonStyle = {
  padding: "10px 50px",
  fontSize: "20px",
  border: "none",
  borderRadius: "200px",
  fontWeight: "bold",
  color: "white",
  margin: "0 10px",
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "2%",
};

function SidewalkCondition(props: { next: any; back: any }) {
  const { isSpanish } = useGlobalContext();
  return (
    <div className="landing-container">
      <div className="top-right-button">
        <SpanishButton />
      </div>

      {!isSpanish ? (
        <>
          <ProgressBar
            steps={[
              "Walk Time",
              "Sidewalk Condition",
              "Crime Safety",
              "Traffic Safety",
              "Thermal Comfort",
            ]}
            currentStep={2}
          />
        </>
      ) : (
        <>
          <ProgressBar
            steps={[
              "Tiempo de Caminata",
              "Condición de la Acera",
              "Seguridad en Relación al Crimen",
              "Seguridad Vial",
              "Comodidad Térmica",
            ]}
            currentStep={2}
          />
        </>
      )}

      <div className="content-container">
        <div className="text-content">
          <h3 className="emoji-header" style={{ marginBottom: "-5%" }}>
            🚶
          </h3>

          {!isSpanish ? (
            <>
              <h1>2. Sidewalk Condition</h1>
              <p>
                Sidewalk conditions indicate the level of maintenance and ease
                of walking.
              </p>

              <p>There are three levels for sidewalk condition:</p>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <button style={{ ...buttonStyle, backgroundColor: "#ff4d4d" }}>
                  Bad
                </button>
                <button style={{ ...buttonStyle, backgroundColor: "#ffbf00" }}>
                  Moderate
                </button>
                <button style={{ ...buttonStyle, backgroundColor: "#00b300" }}>
                  Good
                </button>
              </div>
            </>
          ) : (
            <>
              <h1>2. Condición de la Acera</h1>
              <p>
                Las condiciones de la acera indican el nivel de mantenimiento y
                la facilidad de caminar.
              </p>

              <p>Hay tres niveles para condición de la acera:</p>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <button style={{ ...buttonStyle, backgroundColor: "#ff4d4d" }}>
                  Mala
                </button>
                <button style={{ ...buttonStyle, backgroundColor: "#ffbf00" }}>
                  Moderada
                </button>
                <button style={{ ...buttonStyle, backgroundColor: "#00b300" }}>
                  Buena
                </button>
              </div>
            </>
          )}
        </div>

        <div className="image-content">
          <img src={sidewalk_condition} alt="Sidewalk Condition" />
        </div>
      </div>

      <div className="bottom-left-button">
        <BackButton buttonAction={props.back} />
      </div>
      <div className="bottom-right-button">
        <NextButton buttonAction={props.next} />
      </div>
    </div>
  );
}

function SidewalkConditionBad(props: { next: any; back: any }) {
  const { isSpanish } = useGlobalContext();

  return (
    <div className="landing-container">
      <div className="top-right-button">
        <SpanishButton />
      </div>

      <div className="content-container">
        <div className="text-content">
          {!isSpanish ? (
            <>
              <button
                style={{
                  ...buttonStyle,
                  width: "20%",
                  backgroundColor: "#ff4d4d",
                }}
              >
                Bad
              </button>
              <h1>Sidewalk condition</h1>
              <p>
                A path with <strong>numerous</strong> breaks and{" "}
                <strong>uneven</strong> surface.
              </p>
            </>
          ) : (
            <>
              <button
                style={{
                  ...buttonStyle,
                  width: "20%",
                  backgroundColor: "#ff4d4d",
                }}
              >
                Mala
              </button>
              <h1>Condición de la Acera</h1>
              <p>
                Una acera con <strong>numerosas </strong>roturas y una
                superficie <strong>irregular</strong>.
              </p>
            </>
          )}
        </div>

        <div className="image-content">
          <img src={sidewalk_bad} alt="Bad sidewalk condition" />
        </div>
      </div>

      <div className="bottom-left-button">
        <BackButton buttonAction={props.back} />
      </div>
      <div className="bottom-right-button">
        <NextButton buttonAction={props.next} />
      </div>
    </div>
  );
}

function SidewalkConditionModerate(props: { next: any; back: any }) {
  const { isSpanish } = useGlobalContext();

  return (
    <div className="landing-container">
      <div className="top-right-button">
        <SpanishButton />
      </div>

      <div className="content-container">
        <div className="text-content">
          {!isSpanish ? (
            <>
              <button
                style={{
                  ...buttonStyle,
                  width: "20%",
                  backgroundColor: "#ffbf00",
                }}
              >
                Moderate
              </button>
              <h1>Sidewalk condition</h1>
              <p>
                A path with <strong>some</strong> breaks or{" "}
                <strong>unevenness</strong> in the surface.
              </p>
            </>
          ) : (
            <>
              <button
                style={{
                  ...buttonStyle,
                  width: "20%",
                  backgroundColor: "#ffbf00",
                }}
              >
                Moderada
              </button>
              <h1>Condición de la Acera</h1>
              <p>
                Una acera <strong>con algunas roturas </strong>o desniveles en
                la <strong>superficie</strong>.
              </p>
            </>
          )}
        </div>

        <div className="image-content">
          <img src={sidewalk_moderate} alt="Moderate sidewalk condition" />
        </div>
      </div>

      <div className="bottom-left-button">
        <BackButton buttonAction={props.back} />
      </div>
      <div className="bottom-right-button">
        <NextButton buttonAction={props.next} />
      </div>
    </div>
  );
}

function SidewalkConditionGood(props: { next: any; back: any }) {
  const { isSpanish } = useGlobalContext();

  return (
    <div className="landing-container">
      <div className="top-right-button">
        <SpanishButton />
      </div>

      <div className="content-container">
        <div className="text-content">
          {!isSpanish ? (
            <>
              <button
                style={{
                  ...buttonStyle,
                  width: "20%",
                  backgroundColor: "#00b300",
                }}
              >
                Good
              </button>
              <h1>Sidewalk condition</h1>
              <p>
                A path with <strong>no</strong> breaks and <strong>even</strong>{" "}
                surface.
              </p>
            </>
          ) : (
            <>
              <button
                style={{
                  ...buttonStyle,
                  width: "20%",
                  backgroundColor: "#00b300",
                }}
              >
                Buena
              </button>
              <h1>Condición de la Acera</h1>
              <p>
                Una acera sin roturas y con una{" "}
                <strong>una superficie uniforme</strong>.
              </p>
            </>
          )}
        </div>

        <div className="image-content">
          <img src={sidewalk_good} alt="Good sidewalk condition" />
        </div>
      </div>

      <div className="bottom-left-button">
        <BackButton buttonAction={props.back} />
      </div>
      <div className="bottom-right-button">
        <NextButton buttonAction={props.next} />
      </div>
    </div>
  );
}

function SidewalkConditionTable() {
  const { isSpanish } = useGlobalContext();

  const columnStyle: CSSProperties = {
    width: "32%",
    margin: "10px",
    padding: "20px",
    boxSizing: "border-box",
    boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#f0f0f0",
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "1%",
        padding: "0 4%",
        boxSizing: "border-box",
        width: "100%",
      }}
    >
      {!isSpanish ? (
        <>
          <div className="three-col-column" style={columnStyle}>
            <LevelTag text="Bad" level={1} />
            <p style={{ textAlign: "center" }}>
              A path with <strong>numerous</strong> breaks and{" "}
              <strong>uneven</strong> surface
            </p>
            <img
              src={sidewalk_bad}
              alt="Bad sidewalk condition"
              style={{ width: "65%", maxHeight: "65%", borderRadius: "3%" }}
            />
          </div>
          <div className="three-col-column" style={columnStyle}>
            <LevelTag text="Moderate" level={2} />
            <p style={{ textAlign: "center" }}>
              A path with <strong>some</strong> breaks or{" "}
              <strong>unevenness</strong> in the surface
            </p>
            <img
              src={sidewalk_moderate}
              alt="Moderate sidewalk condition"
              style={{ width: "65%", maxHeight: "65%", borderRadius: "3%" }}
            />
          </div>
          <div className="three-col-column" style={columnStyle}>
            <LevelTag text="Good" level={3} />
            <p style={{ textAlign: "center" }}>
              A path with <strong>no</strong> breaks and <strong>even</strong>{" "}
              surface
            </p>
            <img
              src={sidewalk_good}
              alt="Good sidewalk condition"
              style={{ width: "65%", maxHeight: "65%", borderRadius: "3%" }}
            />
          </div>
        </>
      ) : (
        <>
          <div className="three-col-column" style={columnStyle}>
            <LevelTag text="Mala" level={1} />
            <p style={{ textAlign: "center" }}>
              Una acera con<strong>numerosas </strong>roturas y una superficie{" "}
              <strong>irregular</strong>.
            </p>
            <img
              src={sidewalk_bad}
              alt="Bad sidewalk condition"
              style={{ width: "65%", maxHeight: "65%", borderRadius: "3%" }}
            />
          </div>
          <div className="three-col-column" style={columnStyle}>
            <LevelTag text="Moderada" level={2} />
            <p style={{ textAlign: "center" }}>
              Una acera <strong>con algunas roturas </strong>o desniveles en la{" "}
              <strong>superficie</strong>.
            </p>
            <img
              src={sidewalk_moderate}
              alt="Moderate sidewalk condition"
              style={{ width: "65%", maxHeight: "65%", borderRadius: "3%" }}
            />
          </div>
          <div className="three-col-column" style={columnStyle}>
            <LevelTag text="Buena" level={3} />
            <p style={{ textAlign: "center" }}>
              Una acera sin roturas y con una{" "}
              <strong>una superficie uniforme</strong>.
            </p>
            <img
              src={sidewalk_good}
              alt="Good sidewalk condition"
              style={{ width: "65%", maxHeight: "65%", borderRadius: "3%" }}
            />
          </div>
        </>
      )}
    </div>
  );
}

function SidewalkConditionSummary(props: {
  next?: any;
  back?: any;
  closeHandler?: any;
}) {
  const { isSpanish } = useGlobalContext();
  return (
    <div className="landing-container">
      {props.closeHandler && (
        <div className="top-left-button">
          <CloseButton buttonAction={props.closeHandler} />
        </div>
      )}

      <div className="top-right-button">
        <SpanishButton />
      </div>

      <h2 style={{ alignSelf: "flex-start", marginLeft: "4%" }}>
        {!isSpanish ? <>Sidewalk Condition</> : <>Condición de la Acera</>}
      </h2>
      <SidewalkConditionTable />

      {props.back && (
        <div className="bottom-left-button">
          <BackButton buttonAction={props.back} />
        </div>
      )}

      {props.next && (
        <div className="bottom-right-button">
          <NextButton buttonAction={props.next} />
        </div>
      )}
    </div>
  );
}

export default SidewalkCondition;
export {
  SidewalkConditionBad,
  SidewalkConditionModerate,
  SidewalkConditionGood,
  SidewalkConditionSummary,
  SidewalkConditionTable,
};
