import { NextButton, BackButton, SpanishButton } from "./Button";
import "./Button.css";
import ProgressBar from "./ProgressBar";

function TopButtonContainer(props: {
  nextButtonAction?: () => void;
  nextStyle?: React.CSSProperties;
  backButtonAction?: () => void;
  steps?: string[];
  currentStep?: number;
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginTop: "4%",
        marginBottom: "0%",
      }}
    >
      {props.steps && (
        <ProgressBar steps={props.steps} currentStep={props.currentStep!} />
      )}

      {props.nextButtonAction && (
        <div className="top-right-button">
          <SpanishButton style={props.nextStyle} />
        </div>
      )}
    </div>
  );
}

function BottomButtonContainer(props: {
  nextButtonAction?: () => void;
  nextDisable?: boolean;
  nextText?: string;
  nextStyle?: React.CSSProperties;
  backButtonAction?: () => void;
  backStyle?: React.CSSProperties;
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: "4%",
      }}
    >
      {props.backButtonAction && (
        <div className="bottom-left-button">
          <BackButton
            buttonAction={props.backButtonAction}
            style={props.backStyle}
          />
        </div>
      )}

      {props.nextButtonAction && (
        <div className="bottom-right-button">
          <NextButton
            buttonAction={props.nextButtonAction}
            disable={props.nextDisable}
            text={props.nextText}
            style={props.nextStyle}
          />
        </div>
      )}
    </div>
  );
}

export { TopButtonContainer, BottomButtonContainer };
